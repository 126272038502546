import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Ul from 'components/new/ul'
import Spacer from 'components/new/spacer'

const UserContentTerms = () => {
  return (
    <Layout kind='white'>
      <Helmet>
        <title>User Content Terms &amp; Conditions | Hutson Inc</title>
      </Helmet>

      <Content kind='full'>
        <H2 as='h1'>User Content Terms &amp; Conditions</H2>
        <P>
          You're probably here because we've requested to share your content across our marketing
          channels. We enjoy seeing how our customers are using our products, and we want to gather
          photos and videos from real users. We may reach out to you to request additional rights to
          reproduce images on our website, social media accounts, and more. Whenever possible, we
          also give you credit by including your name or linking to your page. Thank you for
          considering sharing your content with us!
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Terms &amp; Conditions</H3>
        <P>
          As between you and Hutson, Inc. ("Hutson"), you own all content and information you post
          or share (“User Content”), such as posting or sharing comments, photos, and videos.
        </P>
        <P>
          You give Hutson permission to use your User Content as follows: you grant to Hutson a
          license to reproduce, display, perform, distribute, modify, and otherwise use your User
          Content for marketing purposes, including without limitation in Hutson email, website,
          social media sites and other customer communications, as well as with Hutson partners.
          Hutson may display advertisements in connection with your User Content or on pages where
          your User Content may be viewed by you or others, and we may use your User Content to
          advertise and promote Hutson. Hutson's license to your User Content is non-exclusive,
          meaning you may use the User Content for your own purposes or let others use your User
          Content for their purposes. Hutson's license to your User Content is fully paid and
          royalty-free, meaning we do not owe you anything else in connection with our use of your
          User Content. We may exercise our rights anywhere in the world. Finally, our license is
          perpetual, meaning that our license lasts for an indefinite period of time.
        </P>
        <P>You promise that:</P>
        <Ul>
          <li>
            You own all rights to your User Content or, alternatively, that you have the right to
            give Hutson the rights described above;
          </li>
          <li>
            You have paid and will pay in full any fees or other payments that may be related to the
            use of your User Content; and
          </li>
          <li>
            Your User Content does not infringe the intellectual property rights, privacy rights,
            publicity rights, or other legal rights of any third party.
          </li>
        </Ul>

        <P>
          We may refuse to accept or transmit User Content for any reason. We may also remove User
          Content for any reason.
        </P>

        <P>
          If you have any questions about these Terms &amp; Conditions, please feel free to email us
          at <a href='mailto:marketing@hutsoninc.com'>marketing@hutsoninc.com</a>.
        </P>
      </Content>
    </Layout>
  )
}

export default UserContentTerms
